.side-bar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 80px);
  padding: 12px;
  ul {
    background: #1a1c1e;
    color: white;
  }
  .ant-menu-light:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: #606666;
    color: white;
    svg {
      path {
        fill: white;
      }
    }
  }
  .ant-menu-light .ant-menu-item-selected {
    color: white;
    svg {
      path {
        fill: white;
      }
    }
  }
  .ant-menu-item {
    border-radius: 2px;
  }
  .ant-menu-light {
    color: #7b7d7f;
  }
  .profile-data {
    .user-detail {
      max-width: 150px;

      .name {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .email {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #8a8c8e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .ant-menu-title-content {
    font-size: 16px;
  }
}

.ant-layout-sider-light #logo {
  margin-bottom: 10px;
  border-bottom: 2px solid #252728;
  svg {
    width: 170px;
    height: 36px;
    path {
      fill: white;
    }
  }
}

.app-version {
  height: 30px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3f4040;
  color: white;
  border: #606666;
}
